.popup-image {
    width: 25%;
    height: auto;
}

.popup-overlay {
    padding-top: 2%;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #131313;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}

.popup-content {
    width: 100%;
    height: 100%;
    display: block;
}

.vhContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}


.containerBanderas {
    display: flex;
    width: 100%;
    height: 100%;

}

.popup-left {
    padding-right: 5%;
    width: 50%;
    height: 90%;
    display: flex;
    align-items: center;
    justify-content: right;
    background-color: #131313;
}

.popup-right {
    padding-left: 5%;
    width: 50%;
    height: 90%;
    display: flex;
    align-items: center;
    justify-content: left;
}

.popup-left-image1 {
    width: 55%;
    height: auto;
    opacity: 40%;
    transform: translate(76%, 0%);
}

.popup-right-image1 {
    width: 55%;
    height: auto;
    opacity: 40%;
}

.popup-left-image2 {
    width: 30%;
    height: auto;
}

.popup-right-image2 {
    width: 30%;
    height: auto;
    transform: translate(-137%, 0%);

}

.popup-left:hover .popup-left-image1 {
    opacity: 80%;
    transition: 2s;
}

.popup-right:hover .popup-right-image1 {
    opacity: 80%;
    transition: 2s;
}

@media only screen and (max-width: 768px) {
    .popup-image {
        width: 100%;
        height: auto;
        padding: 10%;
    }

    body {
        overflow: hidden;
    }

    .popup-overlay {
        overflow: hidden;
        padding-top: 0%;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #131313;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 9999;
    }

    .popup-content {
        width: 100%;
        height: 100%;
        display: block;
    }

    .vhContainer {
        display: flex;
        align-items: center;
        justify-content: center;
    }


    .containerBanderas {
        display: block;
        width: 100%;
        height: 100%;
    }

    .popup-left {
        width: 100%;
        height: 35%;
        justify-content: center;
    }

    .popup-right {
        background-color: #84848417;
        width: 100%;
        height: 50%;
        justify-content: center;
    }

    .popup-left-image1 {
        width: 65%;
        height: auto;
        opacity: 40%;
        transform: translate(35%, -20%) !important;
    }

    .popup-right-image1 {
        width: 65%;
        height: auto;
        opacity: 40%;
        transform: translate(20%, 0%) !important;
    }

    .popup-left-image2 {
        width: 40%;
        height: auto;
        transform: translate(-70%, -180%) !important;
    }

    .popup-right-image2 {
        width: 40%;
        height: auto;
        transform: translate(-90%, 0%) !important;

    }

    .popup-left:hover .popup-left-image1 {
        opacity: 80%;
        /* transition: 0.7s; */
    }

    .popup-right:hover .popup-right-image1 {
        opacity: 80%;
        /* transition: 0.7s; */
    }
}