.todo {
  background-color: black;
  color: #fff;
}

.page {
  background-color: black;
  color: white;
  display: flex;
}

.title-section {
  padding-top: 2rem;
  padding-bottom: 2rem;
  font-size: 2.5rem;
  font-weight: bold;
  padding-left: 2rem;
  margin: 0;
  background-color: black;
  color: #fff;
}

.imagenes {
  width: 50%;
}

.imagenes img {
  width: 100%;
}

.seguridadBoxes {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.seguridadBoxes p {
  width: 75%;
  text-align: center;
  font-size: 1.1rem;
}

.arriba {
  display: flex;
}

.arriba img {
  width: 30%;
}

.abajo {
  display: flex;
}

.abajo img {
  width: 30%;
}

.centrado {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.celular{
  display: none;
}


@media only screen and (max-width: 780px) {
  .page {
    flex-direction: column;
  }

  .imagenes {
    width: 100%;
  }

  .seguridadBoxes{
    display: none;
  }

  .celular {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 4rem;
  }

  .celular p {
    width: 75%;
    text-align: center;
    font-size: 0.9rem;
  }
  

  .celular p {
    width: 55%;
  }

  .arribaCelular {
    display: flex;
    flex-direction: row;
  }

  .abajoCelular {
    display: flex;
    flex-direction: row;
  }

  .arribaCelular img {
    width: 50%;
  }

  .abajoCelular img {
    width: 50%;
  }

  .abajoCelular.ultimo{
    width: 50%;
  }

  

}