.galeria {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 1rem;
  background-color: black;
  color: white;
}
.carrousel{
  background-color: black;
}

.title {
  padding-top: 2rem;
  padding-bottom: 2rem;
  font-size: 2.5rem;
  font-weight: bold;
  margin: 0.5rem 0;
  padding-left: 2rem;
}

.images {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.images img {
  width: 40rem;
  object-fit: cover;
}

.image-container {
  overflow: hidden;
  margin: 0 0.3rem;
}

.images img:hover {
  cursor: pointer;
  scale: 1.1;
  transition: 500ms;
}

@media only screen and (max-width: 780px) {
 
}