.vehiculos {
    display: flex;
    width: 50%;
    height: max-content;
    flex-direction: column;
    height: max-content;
    align-items: center;
}

.carImage{
  margin-top: -10%;
  margin-bottom: -10%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.hero34Furgoneta{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}


.description{
  display: flex;
  width: 50%;
  align-items: center;
    justify-content: space-around;
    color: #fff;
    flex-direction: column;
    font-size: 2rem;
}

.buttons {
  display: flex;
  padding: 2rem;
}

.buttons button {
  border-radius: 50%; /* Hace que los botones sean redondos */
  width: 4rem;
  height: 4rem;
  border: none;
  cursor: pointer;
  
}

/* Estilos para cada botón */
.buttons button.blanco {
  background-color: white;
  margin-right: 1rem;
}

.buttons button.bluesky {
  background-color: #083a5a;
  margin-right: 1rem;
}

.buttons button.conchevino {
  background-color: #7b160e;
  margin-right: 1rem;
  color: white;
}

.buttons button.gris {
  background-color: #888483;
  margin-right: 1rem;
  color: white;
}

.buttons button.negro {
  background-color: #1d1d1b;
  margin-right: 1rem;
  color: white;
}

.buttons button.plateado {
  background-color: #c7c6c6 ;
  color: white;
}
.textoVehiculos{
  display: flex;
  background-color: #000;
  width: 50%;
  align-items: center;
  justify-content: center;
  
}
.textoVehiculos img{
  width: 80%;
  height: auto;
}

.todo{
  width: 100%;
  display: flex;
  background-color: #b8b8b8;
}

.boxtexto{
  width: 80%;
}



@media (min-width: 320px) and (max-width: 780px) {

      .todo {
        width: 100%;
        flex-direction: column;
    }

    .vehiculos{
      width: 100%;  
    }

    .textoVehiculos{
      width: 100%;
    }

    .textoVehiculos img{
      width: 100%;
      height: auto;
      
    }

    .buttons {
      display: flex;
      padding: 1rem;
    }

    
      .buttons button {
        display: flex;
        width: 1rem;
        height: 1rem;
      }

      .buttons button.blanco {
        background-color: white;
        margin-right: 0.5rem;
      }
      
      .buttons button.bluesky {
        background-color: #083a5a;
        margin-right: 0.5rem;
      }
      
      .buttons button.conchevino {
        background-color: #7b160e;
        margin-right: 0.5rem;
        color: white;
      }
      
      .buttons button.gris {
        background-color: #888483;
        margin-right: 1rem;
        color: white;
      }
      
      .buttons button.negro {
        background-color: #1d1d1b;
        margin-right: 1rem;
        color: white;
      }
      
      .buttons button.plateado {
        background-color: #c7c6c6 ;
        color: white;
      }

}