@media only screen and (max-width: 950px) {
  .menuHome {
    width: 85%;
  }
}


@media only screen and (max-width: 768px) {
  .searchIcon {
    display: none;
  }

  .bombaKawa {
    transform: translate(0%, -75%);
  }

  .tecJaponesa {
    transform: translate(20%, -75%);
  }

  .escoderMovil {
    display: none !important;
  }

  .mostrarMovil.ficha {
    padding: 1rem !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }


  .alinear-abajo {
    display: flex;
  }


  .mostrarMovil.letras {
    background-color: #191713;
    color: #fff;
    height: 100%;
    width: 100%;
    padding-top: 1rem;
    padding-bottom: 1rem;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
    font-size: 1.5rem;
  }

  .mostrarMovil.ficha img {
    width: 100%;
  }

  .mostrarMovil {
    display: block !important;
  }

  .sinotrukContainer img {
    width: 60%;
  }

  .serieContainerAutos {
    flex-direction: column !important;
    width: 100% !important;
  }

  .serieContainerAutos div a img {
    width: 100% !important;
    height: auto !important;
    padding: 7vw !important;
  }

  .containerAutos {
    width: 100%;
    padding: 4vw;
  }

  .vehiculos.fondoautos {
    width: 100%;
  }


  .closeMovil::before {
    content: url(../public/images/back.png);
    position: absolute;
    top: -20px;
    left: -45px;
    transform: scale(0.35);
  }

  .headerSubMenu {
    position: relative;
  }

  .menuImgContainer {
    text-align: center;
  }

  .logo {
    padding-right: 25px;
    width: 60vw;
  }

  .serieSinotrukAutos {
    display: flex;
    align-items: center;
    justify-content: center;
    align-items: start;
    width: 100%;

  }

  .banner .menosImagenMovile {
    width: 80%;
  }



  .serieSinotrukAutos .logos {
    width: 100%;
  }

  .nav-links>li {
    width: 100% !important;
  }

  .nav-links {
    width: 100% !important;
    transition: All 0.5s ease;
  }

  .nav-header {
    width: 100% !important;
    transition: All 0.5s ease;
    overflow-y: scroll;
    z-index: -1;
  }

  .menu2 {
    display: none !important;
  }

  .internal .nav-links-h {
    display: none !important;
  }



  .boxesIni.partesbg {
    flex-direction: row !important;
    flex-wrap: wrap;
  }

  .boxesIni.partesbg>div {
    width: 50% !important;
  }

  .posRelative {
    top: 0px;
  }

  .navSearch {
    display: none;
  }

  .boxesIni {
    flex-direction: column;
  }

  .boxesIniHeader {
    flex-direction: column;
  }

  .boxesIniHeader .boxLeftHeader,
  .boxesIniHeader .boxRightHeader {
    width: 100%;
  }

  .content3 {
    flex-direction: column-reverse;
  }

  .boxesIni>div {
    width: 100% !important;
  }

  .colMenu {
    width: 100% !important;
  }

  .rowMenu {
    flex-direction: column;
  }

  .headerSubMenu {
    flex-direction: row-reverse;
    justify-content: center !important;
  }

  .colMenu p {
    text-align: center;
  }

  .item-level-3 .colItem {
    margin-right: 0px !important;
  }

  .boxesContact {
    padding: 3vh 8vw;
  }

  .footer {
    padding: 50px 20px 100px 20px !important;
    flex-direction: column !important;
  }

  .footer .desc {
    padding-right: 0px;
    width: 100%;

  }

  .footer .info {
    width: 100%;
    text-align: center;
  }

  .footer .col1 {
    display: none;
  }

  .footer .info .social img {
    width: 15% !important;
  }

  .boxesContact {
    padding: 6vh 10vw !important;
  }

  .footer .info img {

    width: 60% !important;

  }

  .boxesContact .buttonContact {
    margin-bottom: 30px;
  }

  .popup {
    position: absolute;
    bottom: 20%;
    top: 20%;
    max-height: 5.8rem;
    font-size: larger;
    left: 15px;
    right: 15px;
    background-color: #a3292d;
    color: white;
    padding: 1.6rem !important;
    margin-top: 13rem;
    text-align: center !important;
    min-height: 3.5rem;
  }

  .text_icon_left {
    order: 2;
    border-style: none;
    width: 50%;
    padding: 0 0 0 0%;
  }

  .text_icon_center {
    order: 4;
    border-style: none;
    width: 50%;
    padding: 0 0% 0 0%;
  }

  .icon_right {
    order: 3;
    border-style: none;
    width: 50%;
    padding: 0 0% 0 0%;
  }

  .icon_center {
    order: 1;
    border-style: none;
    width: 50%;
    padding: 0 4% 0 4%;
  }

  .text_icon_center p {
    font-size: 26px;
    text-align: center;
  }

  .text_icon_left p {
    text-align: center;
  }

  .textoImagenesProducto {

    padding: 4% 0 4% 0;
  }

  .noResponsive {
    display: none;
  }

  .responsive {
    display: block;
  }

  .each-slide-effect>div {
    height: 72vw !important;
  }

  ul.nav-links-h>li>a:hover,
  .active {
    color: #f44331;
    border-bottom-color: #f44331;
    border-bottom-width: 0px !important;
    border-bottom-style: solid;
  }


  .responsive .each-slide-effect>div {
    height: 100vw !important;
  }

  .precioCamiones {
    font-size: 9vw;
  }

  .vidaUtil {
    font-size: 4vw !important;
  }

  .textoMotor1 {
    font-size: 13vw;
    bottom: 7rem;
  }

  .imgHeader1 {
    background-position: 50% !important;
  }

  .textoMotor2 {
    font-size: 10vh;
    bottom: 5.5rem;

  }

  .textoMotor3 {
    font-size: 6vw;
    bottom: 3.5rem;
  }

  .flex-row.textoMotor2 .excavadoraH1 {
    font-size: 5.5vw !important;
  }

  .textoMotor3.negro {
    bottom: 300px !important;
    padding-top: 20vw !important;
  }


  .textoMotorFlecha {
    bottom: 3.5rem;
    left: 45vw;
  }

  .textoMotor4 {
    font-size: 5vw;
    left: 11px;
  }

  .precioExcavadora {
    font-size: 6vw;
  }

  .boxesIniHeader.main.compania .boxLeftHeader {
    padding: 3% 2% 3% 2%;
    display: inline-block;
  }

  .boxesIniHeader.main.compania .boxRightHeader {
    padding: 3% 2% 3% 2%;
    display: inline-block;
  }

  .boxesIni.posRelative.news.compania {
    flex-direction: initial;
    padding: 20% 2% 20% 2%;
  }

  .boxNews.companiaimg2.b {
    width: 50% !important;
  }

  .boxNews.companiaimg2 {
    width: 33.33% !important;
  }

  .boxNews.companiaimg2.fif img {
    max-width: 100% !important;
  }

  .boxNews.companiaimg2.c {
    width: 100% !important;
  }

  audio {
    height: 30px !important;
    width: 75vw !important;
  }

  .boxPlayerMotor {
    left: 17px;
    bottom: 40px;
  }

  .textoMotor4.negro {
    color: #000
  }



  .boxesIni.posRelative.news.repuesto {
    flex-direction: initial;
  }

  .boxesIni.posRelative.news.repuesto img {
    max-width: 100%;
  }

  .modalForm {
    width: 100%;
  }


  .item-level-8.linkContact {
    padding: 0 0 0 0;
  }

  .colTitle {
    font-size: 7.4vw !important;
  }

  .boldserie {
    font-weight: 700;
    font-size: 4.4vw;
  }

  .imgnMenu {
    margin: 0 0 2px 10px;
  }

  .escritorio {
    display: none;
  }

  .movil {
    display: block;
  }

  .textContact.movil {
    margin-top: 6%;
  }

  .btn-wsp {
    width: 63px;
    height: 63px;
    line-height: 66px;
  }

  .videoWidth.produ {
    height: max-content;
    width: 100%;
    object-fit: cover;
    overflow: hidden;
  }

  .serieList {

    align-content: center;
  }


  .bannerTec.responsive img {
    max-width: 100%;
    margin-left: 0;
  }

  .serieTitle.serieBox.seriecompletomovil {
    width: 80%;
    padding: 8vh 10vw 3vh 10vw
  }

  .boxRight.nomSeries.responsive {
    justify-content: center;
    background-color: #fff;
  }

  .boxRight.nomSeries.responsive p {
    color: #2d2d2d;
    text-align: -webkit-center;
    font-size: 7.2vw;
    line-height: 7vw;
    margin: 10% 0;
  }
}


@media only screen and (min-width: 768px) {}

@media only screen and (max-width: 1300px) {}

@media only screen and (max-width: 1160px) {}

@media only screen and (max-width: 1000px) {}

@media only screen and (max-width: 880px) {}

@media only screen and (max-width: 768px) {

  .block1>div,
  .block2>div,
  .block3>div,
  .block4>div {
    width: 100%;
  }

  .block1 .overlayBox {
    position: absolute;
    bottom: 0px;
    width: 100%;
    height: 29vw;
    background-color: rgba(0, 0, 0, 0.8);
  }

  .block1 .titleBox {
    font-size: 6.5vw;
    color: #fff;
    padding: 4vw 1.5vw 0vw 3vw;
    display: inline-block;
  }

  .block1 .linksBox {
    display: flex;
    flex-direction: row;
    position: absolute;
    bottom: 0px;
    padding: 0vw 2.5vw 5vw 3vw;
    flex-wrap: wrap;
  }

  .block1 .linksBox>a {
    display: inline-block;
    color: #fff;
    font-size: 4.6vw;
    text-decoration: none;
    margin-right: 10px;
  }

  .nav-search-active {
    display: none !important;
  }

  .block3.boxBlock>div {
    width: 90%;
    background-color: #2d2d2d;
  }

  .modelosFurgonetas {
    font-size: 1.5rem;
  }

  .block3 .bottomContainer {
    color: #fff;
    padding: 5vw 2vw;
  }

  .block3 .bottomContainer .titleBox,
  .block3 .bottomContainer .titleBox2 {
    font-size: 5.5vw;
    margin: 0 0.5vw;
  }

  .block3 .bottomContainer .linksBox {
    font-size: 4.6vw;
    margin-top: 4vh;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: flex-start;

  }

  .block3 .bottomContainer .linksBox a {
    margin: 0 2%;
  }


  .sinoIcon {
    display: none;
  }

  .btnConcesionarios {
    width: 100%;
  }

  .boxConcesionarios table {
    width: 90%;
  }

  .concesionariosFlexText {
    width: 100%;
  }

  .imgConcesionario img {
    width: 100%;
  }

  .concesionariosFlex {
    flex-direction: column-reverse;
  }

  .imgConcesionario {
    text-align: left;
  }

  .tresillo {
    width: 30%;
  }

  .cuatrosillo {
    width: 40%;
  }

  .concesionariosFlex {
    gap: 5vw;
  }

  .imgContainerHeader,
  #videoContainer {
    min-height: 60vh;
  }

  .logo::after {
    content: url(../public/images/leon-web.png);
    position: absolute;
    transform: scale(0.4);
    right: -49px;
    top: -38px;
  }

  .desktopImg {
    display: none;
  }

  .nomSeries {
    display: flex;
  }

  .nomSeries>div.serieTitle {
    width: 40%;
  }

  .nomSeries>div.serieList {
    width: 60%;
  }

  .serieContainer {
    font-size: 4.3vw;
    width: 25vw;
  }

  .serieTitle1 {
    font-size: 7.2vw;
    line-height: 4vw;
  }

  .serieTitle {
    font-size: 15vw;
    line-height: 15vw;
    padding: 13vh 10vw;
  }

  audio#audioMotorHome {
    width: 45vw !important;
  }

  .boxPlayerMotor.audiohome {

    width: 100%;
    left: 0;
    bottom: 5vh;
    text-align-last: center;
  }

  .bannerTec {
    display: flex;
    text-align-last: center;
    max-width: 100%;
  }

  .textConcesionario {
    text-align: left !important;
  }

  .bannerTec img {
    max-width: 50%;
    margin-left: 25%;
  }

  .cuotas {
    font-size: 4.2vw;
  }

  .tituloMenu {
    display: none;
  }

  .menuHome .titleHome {
    display: none;
  }

  .menuHome {
    width: 70%;
  }

  .internal .burger {
    width: 25%;
  }

  .btn-wsp {
    bottom: 90px;
    right: 0px;
  }

  .boxesContact p.fixed {
    bottom: 25px;
    margin-bottom: 0px !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
    left: 50%;
    transform: translate(-50%);
    width: 80%;
  }

  .internal .navSearch {
    left: 2vw !important;
  }

  .result {
    top: 13vh !important;
  }

  .requisitosDos.gara.new {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: justify;
  }

  .boxNews.companiaimg2.new {
    width: 100% !important;
  }
}

@media only screen and (min-width: 768px) {
  .movilImg {
    display: none;
  }
}