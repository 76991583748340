* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.form-box {
    min-width: 100%;
    margin: 0vw;
    padding: 2vw 5vw;
    position: relative;

}

.form-box h5 {
    font-size: 35px;
    text-align: center;
    color: #c41a2f;
    margin: 0 0 .5vh 0;
}

.form-box label {
    display: block;
    text-align: center;
    font-size: 20px;
    margin: 0 0 2vh 0;
}

.form-box input,
textarea,
select {
    padding: .5rem .8rem .5rem .8rem;
    border: none;
    font-size: 17px;
    margin-left: 20px;
}

.form-box input:focus,
textarea:focus,
select:focus {
    border: none;
    outline: none;
}

.form-box input {
    height: 42px;
    width: 85%;
}

textarea {
    width: 95%;
}

.form-box select {
    height: 42px;
    width: inherit;
}

.form-box textarea {
    height: 15vh;
}

.input-group {
    border-style: solid;
    border-color: #ccc;
    border-width: 1px;
    border-radius: 4px;
}

.nextBtn {
    display: inline-block;
    width: 100%;
    height: 2rem;
    color: red;
    border: 0;
    bottom: 0;
    cursor: pointer;
    font-size: 17px;
}

.nextBtnDisabled {
    display: inline-block;
    width: 100%;
    height: 2rem;
    color: red;
    border: 0;
    bottom: 0;
    cursor: pointer;
    font-size: 17px;
    margin: 1rem 8rem 1rem 8rem;
    border-radius: 5px;
    background-color: #fff;
    color: gray;
    margin: 1rem 0 1rem 0;
    display: inline-block;
}



.nextBtn {

    margin: 1rem 8rem 1rem 8rem;
    border-radius: 5px;
    background-color: #c41a2f;
    color: #fff;
}

.nextBtn:hover {

    transition: 1s bground ease;
}

.nextBtn {
    margin: 1rem 0 1rem 0;
    display: inline-block;
}

.input-group {
    position: relative;
    display: flex;
    width: 100%;
    margin-bottom: 15px;
    background-color: white;
}


.form-box .userIcon {
    position: relative;
    top: 5px;
    left: 10px;
}

.form-box .userIcon img {
    width: 25px !important;
    padding: 3px;
}

.input_title {
    text-align: left !important;
    margin: 0px !important;
    color: #9e9e9e !important;
    font-size: 17px !important;
    height: 20px;
}

input[type=text] {
    color: #999;
}

.errorClass {
    border-color: rgb(255, 0, 0) !important;
}


@media only screen and (max-width: 768px) {
    .form-box select {
        width: inherit;
    }
}


.modalForm .form-box {
    height: 90vh;
}

.contenedorTerminos {
    display: flex;
    align-items: center;
}

.linkTerminos {
    color: #373737 !important;
}

.linkTerminos:hover {
    color: #c41a2f !important;
    text-decoration: underline;
}

.contenedorTerminos input {
    width: 20px;
    margin-bottom: 13px;
}

.contenedorTerminos label {
    font-size: 1.1rem;
    color: #373737;
    margin-left: 2vw;
}

.privaty {
    color: #373737;
    background-color: #fff;
    margin-left: 10vw;
    margin-right: 10vw;
    margin-top: 2vw;
    margin-bottom: 2vw;
    border: solid 1px black;
    border-radius: 25px;
}

.privaty p {
    font-size: 1.3rem;
}

.texto h1 {
    display: inline-block;
    text-align: center;
    color: #000;
    border-bottom: solid 2px #c41a2f;
    position: relative;
    padding-bottom: 10px;

}

.texto {
    margin: 2vw;
    text-align: justify;
}

.black {
    font-weight: bold;
}

.espacioText {
    padding-right: 3rem;
    padding-left: 3rem;
}